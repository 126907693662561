import { defineStore } from 'pinia'
import { getCompany } from 'src/api/company';
import { useUserStore } from './user';
import { useFirmStore } from './firm';

export const useCompanyStore = defineStore('company', {
  state: () => ({
    currentCompanyId: null,
    currentCompany: null
  }),

  getters: {
    getCurrentCompanyId(state) {
      return state.currentCompanyId
    },
    getCurrentCompany(state) {
      return state.currentCompany
    },
    isNotLicensed(){
      return ! this.getCurrentCompany.license.isValid
    }
  },

  actions: {
    async setCurrentCompanyId(companyId) {
      this.currentCompanyId = companyId;
      await this.fetchCurrentCompany();
      this.setUserTypeAndUserableId();
    },
    async fetchCurrentCompany() {
      let { data } = await getCompany();
      this.currentCompany = data.company;
    },
    setUserTypeAndUserableId() {

      let type = this.getCurrentCompany?.is_owned_by_me ? 'company' : 'accountant';
      useUserStore().setUserType(type);

      if (this.getCurrentCompany.is_owned_by_me) {
        useUserStore().setUserableId(this.getCurrentCompanyId);
      } else {
        this.setCurrentFirm();
        useUserStore().setUserableId(this.getCurrentCompany.accountant.id);
      }
    },
    setCurrentFirm() {
      if (this.currentCompany?.accountant) useFirmStore().setCurrentFirmManually(this.getCurrentCompany.accountant);
    }
  }
})