import { defineStore } from 'pinia'

export const useGlobalUiStore = defineStore('globalUi', {
  state: () => ({
    premiumDialog: {
      visibile: false
    }
  }),

  getters: {
    getPremuimDialog(state) {
      return state.premiumDialog
    }
  },

  actions: {
    togglePremiumDialog(visibility = true) {
      this.premiumDialog.visibile = visibility
    }
  }
})