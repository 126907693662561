import {api} from "boot/axios";
import {getSelectedCompany} from "src/api/helpers";
import { useUserStore } from "src/stores/user";

export async function getUser() {
  const {status, data, error} = await api.get("/me")
  if (status) return data.user
  else return null
}

export async function hideCustomisationBanner() {
  const { status, data, error } = await api.put("/me", {
    name:useUserStore().getUser.name,
    meta_data: {
      ...useUserStore().getUser?.meta_data,
      hide_customisation_banner: true,
    },
  });
  if (status) return data.user;
  else return null;
}

export async function hideVideo(videoName) {
  let metadata = useUserStore().getUser?.meta_data;
  Array.isArray(metadata.hidden_videos) ? metadata.hidden_videos.push(videoName) : metadata.hidden_videos = [videoName];
  const { status, data, error } = await api.put("/me", {
    name:useUserStore().getUser.name,
    meta_data: metadata
  });

  if (status) return data.user;
  else return null;
}

export async function canManageCompany() {
  const selectedCompany = getSelectedCompany();
  const {status, data, error} = await api.get(`canManageCompany/${selectedCompany}`);
  return status;
}

export async function logout() {
  const { status, data, error } = await api.post('/logout');
  return {status , data, error}
}

export async function deleteAccount() {
  const {status, data, error} = await api.delete('/me');
  return {status, data, error};
}

/**
 * @param {{'current_password', 'password','password_confirmation' }} credentials
 * @returns
 */
export async function resetPassword(credentials) {
  const {status, data, error} = await api.post('/reset-password',credentials);
  return {status, data, error};
}

export async function updateUser(user,avatarFile = null) {

  const formData = new FormData();

  formData.append('name', user.name);
  formData.append('phone_number', user.phone_number);
  formData.append('meta_data', JSON.stringify(user.meta_data) );
  if (avatarFile != null) {
    formData.append('avatarFile', avatarFile);
  }


  const { status, data, error } = await api.post("/me", formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return { status, data: data?.user, error }
}

export async function getNotifications() {
  const { status, data, error } = await api.get(`me/notifications`);
  return { status, data, error };
}

export async function readNotification(notification_id) {
  const { status, data, error } = await api.put(`me/notifications/${notification_id}`);
  return { status, data, error };
}

export async function readAllNotifications() {
  const { status, data, error } = await api.post(`me/notifications`);
  return { status, data, error };
}