import {api} from "boot/axios";

export async function getAccountantCompanies(accountant_id){
  const {status, data, error} = await api.get(`/accountants/${accountant_id}`);
  return {status, data, error};
}

export async function disassociateCompany(company_id) {
  const {status, data, error} = await api.post(`/companies/${company_id}/disassociate`);
  return {status, data, error};
}

/**
 *
 * @param {string} accountant_id
 * @param {{'name','phone_number','company_name','unique_id','country_id','state_id','street','zip' }} profileInformation
 * @param avatarFile
 * @returns
 */
export async function updateAccountant(accountant_id, profileInformation,avatarFile = null) {

  const formData = new FormData();
    for (const key in profileInformation) {
        if (profileInformation[key]) {
          if (key === 'meta_data') {
            formData.append('meta_data', JSON.stringify(profileInformation[key]));
            continue
          }
        formData.append(key, profileInformation[key]);
        }
    }
    if (avatarFile) {
      formData.append('avatarFile', avatarFile);
    }

  const {status, data, error} = await api.post(`/accountants/${accountant_id}`,formData,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return {status, data, error};
}


export async function getFirms(filters = {},pagination = {}) {
  const params = {
    keyword: filters.keyword,
    ability: filters.ability,
  }

  if (pagination.value) {
    params.page =   pagination.value.page
    params.perPage =   pagination.value.perPage
  }

  let { status, data, error } = await api.get(`firms`,
    {
      params: params
    });
  const meta = data?.meta;
  data = data.data;

  return { status, data, meta,error };
}

export async function deleteFirm(accountant_id) {
  const {status, data, error} = await api.delete(`firms/${accountant_id}`);
  return {status, data, error};
}

export async function getFirmUsers(accountant_id) {
  const {status, data, error} = await api.get(`firms/${accountant_id}/users`);
  return {status, data, error};
}

export async function getFirmUser(accountant_id,firmUser_id) {
  const {status, data, error} = await api.get(`firms/${accountant_id}/users/${firmUser_id}`);
  return {status, data, error};
}

export async function givePermissionToFirmUser(accountant_id,firmUser_id,options={allow:false,company_id:null}) {
  const {status, data, error} = await api.put(`firms/${accountant_id}/users/${firmUser_id}`,options);
  return {status, data, error};
}

export async function disassociateFirmUser(accountant_id,firmUser_id) {
  const {status, data, error} = await api.delete(`firms/${accountant_id}/users/${firmUser_id}`);
  return {status, data, error};
}