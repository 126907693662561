import {defineStore} from 'pinia'
import { useUserStore } from './user';
import { getAccountantCompanies, getFirmUsers } from 'src/api/accountant';

export const useFirmStore = defineStore('firm', {
  state: () => ({
    currentFirm:null,
    users:[]
  }),

  getters: {
    getCurrentFirm(state) {
      return state.currentFirm
    },
    isNotLicensed() {
      return !this.getCurrentFirm().license.isValid;
    },
    getUsers(state) {
      return state.users
    }
  },

  actions: {
    async refreshCurrentFirm(){
      await this.setCurrentFirm(this.currentFirm.id)
    },
    async setCurrentFirm(firmId) {
      await this.fetchCurrentFirm(firmId);
      this.setUserTypeAndUserableId(this.getCurrentFirm);
    },
    async fetchCurrentFirm(firmId){
      let { data } = await  getAccountantCompanies(firmId);
      this.currentFirm = data.accountant;
    },
    setUserTypeAndUserableId(firm){
      let type = firm.is_owned_by_me ? 'firm_owner' : 'accountant';
      useUserStore().setUserType(type);
      useUserStore().setUserableId(firm.id);
    },
    setCurrentFirmManually(firm) {
      this.currentFirm = firm;
    },
    async fetchUsers(){
      let { data } = await getFirmUsers(this.currentFirm.id);
      this.users = data;
    },
    async refreshCurrentFirmAndUser(){
      this.refreshCurrentFirm();
      this.fetchUsers();
    }
  }
})