import { defineStore } from 'pinia'
import {getUser, readAllNotifications} from "src/api/users";
import { useCompanyStore } from './company';
import { useFirmStore } from './firm';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null
  }),

  getters: {
    getUser(state) {
      return state.user
    },
    getUserableSocketChannel(state) {
      switch (state.user.type) {

        case 'company':
          return `companies.${useCompanyStore().getCurrentCompanyId}`;

        case 'accountant':
          return `firms.${ useFirmStore().getCurrentFirm.id }`;

        default:
          return null;
      }
    },
    isNotLicensed(){
      return ! this.getUser.license.isValid;
    },
    isOnTrial(){
      return this.getUser.license.isOnTrial;
    },
    isSubscribed(){
      return this.getUser.license.isSubscribed;
    },
    trialEndsAt(){
      return this.getUser.license.trialEndsAt;
    }
  },

  actions: {
    async setUser() {
      this.user = await getUser();
      if (useCompanyStore().getCurrentCompanyId)
        useCompanyStore().setUserTypeAndUserableId();
    },
    setUserManually(user) {
      this.user = user;
    },
    incrementNotificationCount() {
      this.user.unread_notifications_count++;
    },
    decrementNotificationCount() {
      this.user.unread_notifications_count--;
    },
    setUserType(type) {
      this.user.type = type;
    },
    setUserableId(id) {
      this.user.userable_id = id;
    },
    async markAllNotificationsAsRead() {
      if (this.user.unread_notifications_count === 0) return false;

      const { status,error} = await readAllNotifications()
      if (status) {
        await useUserStore().setUser();
        return true;
      }
    }
  }
})